import Vue from "vue";
import VueRouter from "vue-router";
import IndexView from "../views/index/IndexView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: IndexView,
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/index/HomeView.vue"),
      },
      {
        path: "/tools",
        name: "tools",
        component: () => import("../views/index/ToolsView.vue")
      },
      {
        path: "/wzkAdmin",
        name: "wzkAdmin",
        component: () => import("../views/index/WzkAdminView.vue"),
      },
    ],
  },
  {
    path: "/journal",
    name: "journal",
    component: () => import("../views/JournalView.vue"),
  },
  {
    path: "/scroll",
    name: "scroll",
    component: () => import("../views/ScrollView.vue"),
  },
  {
    path: "/gsap",
    name: "gsap",
    component: () => import("../views/GSAPView.vue"),
  },
  {
    path: "/wardrobe",
    name: "wardrobe",
    component: () => import("../views/WardrobeView.vue"),
  },
  {
    path: "/metronome",
    name: "metronome",
    component: () => import("../views/MetronomeView.vue"),
  },{
    path: "/rayleighProsperity",
    name: "rayleighProsperity",
    component: () => import("../views/RayleighProsperityView.vue"),
  },
  {
    path: "/temp",
    name: "temp",
    component: () => import("../views/TempView.vue"),
  },
  {
    path: "/lotteryTicket",
    name: "lotteryTicket",
    component: () => import("../views/LotteryTicketView.vue"),
  },

  {
    path: "*",
    component: () => import("../views/404View.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
